// TODO: publish to npm
// TODO; support shortcuts with + character

import { useEffect, useRef } from 'react'

const MODIFIERS = ['Meta', 'Alt', 'Control']

export const useShortcuts = shortcuts => {
  const activeKeys = useRef(new Set())

  useEffect(() => {
    const isShortcut = (shortcut, event) => {
      const parts = shortcut.split('+')
      const keys = parts.filter(key => !MODIFIERS.includes(key))
      const modifiers = parts.filter(key => MODIFIERS.includes(key))

      return (
        keys.length === activeKeys.current.size &&
        keys.every(key => activeKeys.current.has(key)) &&
        event.metaKey === modifiers.includes('Meta') &&
        event.ctrlKey === modifiers.includes('Control') &&
        event.altKey === modifiers.includes('Alt')
      )
    }

    const handleKeydown = event => {
      if (activeKeys.current.has(event.key)) {
        return
      }
      if (MODIFIERS.includes(event.key)) {
        return
      }
      activeKeys.current.add(event.key)

      for (const [shortcut, fn] of Object.entries(shortcuts)) {
        if (isShortcut(shortcut, event)) {
          event.preventDefault()
          activeKeys.current.clear()
          fn()
        }
      }
    }

    const handleKeyup = event => {
      if (!activeKeys.current.has(event.key)) {
        return
      }
      activeKeys.current.delete(event.key)
    }

    // Keyup events are not fired if the page is blurred, so manually remove
    // all keys on blur
    const handleBlur = event => {
      activeKeys.current.clear()
    }

    window.addEventListener('keydown', handleKeydown)
    window.addEventListener('keyup', handleKeyup)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
      window.removeEventListener('keyup', handleKeyup)
      window.removeEventListener('blur', handleBlur)
    }
  }, [shortcuts])
}
