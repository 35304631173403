import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { VscClose, VscFile } from 'react-icons/vsc'

import {
  addOrReplaceRoom,
  getRooms,
  removeRoomById
} from '../lib/RoomStorage.js'
import { logEvent } from '../lib/analytics.js'
import { origin } from '../config.js'
import { fetcher } from '../lib/fetcher.js'
import { shouldReportError } from '../lib/errors.js'

import { ButtonLink } from './ButtonLink.js'
import { CopyButton } from './buttons/CopyButton.js'
import { ExternalLinkIcon } from './icons/ExternalLinkIcon.js'
import { RelativeTime } from './RelativeTime.js'

const REFRESH_RATE = 300_000 // 5 minutes

export const RoomList = ({ onChange = () => {} }) => {
  const [rooms, setRooms] = useState([])

  const updateRooms = async rooms => {
    try {
      for (const room of rooms) {
        await addOrReplaceRoom(room)
      }
      setRooms(await getRooms())
    } catch (err) {
      if (shouldReportError(err)) throw err
    }
  }

  const removeRooms = async rooms => {
    try {
      for (const room of rooms) {
        await removeRoomById(room.id)
      }
      setRooms(await getRooms())
    } catch (err) {
      if (shouldReportError(err)) throw err
    }
  }

  useEffect(() => {
    const checkExpiration = async (initialRooms = []) => {
      let roomList
      if (initialRooms.length > 0) {
        roomList = initialRooms
      } else {
        try {
          roomList = await getRooms()
        } catch (err) {
          if (shouldReportError(err)) throw err
          return
        }
      }
      let hasChanges = false
      for (const room of roomList) {
        try {
          const { remainingDownloads } = await fetcher.get(
            `/api/room/${room.id}/remaining-downloads`,
            {
              headers: {
                Authorization: `Bearer sync-v1 ${room.writerToken}`
              },
              retry: true
            }
          )

          if (remainingDownloads !== room.remainingDownloads) {
            room.remainingDownloads = remainingDownloads
            hasChanges = true
          }
        } catch (err) {
          console.log(err)
          return // Ignore error
        }
      }

      if (hasChanges) updateRooms(roomList)
    }

    ;(async () => {
      try {
        // Initialize rooms
        const initialRooms = await getRooms()
        setRooms(initialRooms)

        // Trigger first time
        await checkExpiration(initialRooms)

        const interval = setInterval(checkExpiration, REFRESH_RATE)

        return () => {
          clearInterval(interval)
        }
      } catch (err) {
        if (shouldReportError(err)) throw err
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const roomsToRemove = rooms.filter(r => r.remainingDownloads === 0)
    if (roomsToRemove.length > 0) removeRooms(roomsToRemove)
    else onChange(rooms)
  }, [onChange, rooms])

  const handleClose = async room => {
    removeRooms([room])
  }

  return (
    <Stack spacing={[6, null, 4]} px={[0, null, 2]}>
      {rooms.map(room => (
        <RoomItem key={room.id} room={room} onClose={handleClose} />
      ))}
    </Stack>
  )
}

export const RoomItem = ({ room, onClose = () => {} }) => {
  const { id, title, size, key } = room
  const { i18n, t } = useTranslation()

  const path = `/${id}#${key}`
  const url = `${origin}${path}`

  const handleClickCopy = () => {
    logEvent('share', { type: 'copy' })
  }

  return (
    <Box p={3} borderWidth={2} borderColor='whiteAlpha.600' borderRadius='xl'>
      <VStack>
        <HStack w='100%' d='flex'>
          <Icon as={VscFile} boxSize={7} />
          <VStack align='left' spacing={0} flex='1' overflow='auto'>
            <Box as='b' fontSize='sm' isTruncated>
              {title}
            </Box>
            <Box fontSize='sm'>{i18n.format(size, 'bytes')}</Box>
          </VStack>
          <Box alignSelf='end'>
            <Button
              onClick={() => onClose(room)}
              colorScheme='gray'
              size='xs'
              align='end'
            >
              <Icon as={VscClose} boxSize={5} />
            </Button>
          </Box>
        </HStack>
        <Text w='100%' color='whiteAlpha.800' fontSize='sm'>
          <Trans
            t={t}
            i18nKey='roomItem.expiresAt'
            count={room.remainingDownloads}
            components={{
              relativetime: <RelativeTime to={room.expiresAtTimestampMs} />
            }}
          />
        </Text>
      </VStack>
      <Divider my={2} />
      <Flex justify='space-between' align='center'>
        <ButtonLink
          leftIcon={<Icon as={ExternalLinkIcon} boxSize={4} />}
          href={path}
          colorScheme='gray'
          size='xs'
        >
          {t('roomItem.open')}
        </ButtonLink>
        <CopyButton
          onClick={handleClickCopy}
          text={url}
          colorScheme='gray'
          size='xs'
        />
      </Flex>
    </Box>
  )
}
