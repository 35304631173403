import { EventEmitter } from 'events'
import reemit from 're-emitter'
import Debug from 'debug'

const debug = Debug('wormhole:Send')

export const JOIN_MODE = 'join'
export const CREATE_MODE = 'create'

export class Send extends EventEmitter {
  mode = null
  room = null
  destroyed = false

  _cleanupListeners = null

  // Can be called repeatedly
  async join (id = null, key = null) {
    if (id === (this.room?.id || null)) return
    debug(`join(id=${id} key=${key})`)

    this._destroyRoom()
    if (id) {
      const { Room } = await import('./Room.js')
      if (this.destroyed) {
        return
      }
      this._setMode(JOIN_MODE)
      this._createRoom(Room)
      try {
        await this.room.join(id, key)
      } catch (err) {
        this._setMode(null)
        throw err
      }
    }
  }

  async create (uploadFiles) {
    const { Room } = await import('./Room.js')
    if (this.destroyed) {
      return
    }

    debug('create(uploadFiles=%o)', uploadFiles)
    this._destroyRoom()
    this._setMode(CREATE_MODE)
    this._createRoom(Room)
    try {
      await this.room.create(uploadFiles)
    } catch (err) {
      this._setMode(null)
      throw err
    }
  }

  _setMode (mode) {
    this.mode = mode
    this.emit('mode', mode)
  }

  _createRoom (Room) {
    this.room = new Room()
    this._cleanupListeners = reemit(this.room, this, [
      'peerState',
      'cloudState',
      'createProgress',
      'downloading',
      'meta',
      'url',
      'files'
    ])
    this.room.on('error', this._destroyRoom)
  }

  _destroyRoom = err => {
    if (!this.room) return

    this._cleanupListeners?.()
    this._cleanupListeners = null
    this.room.off('error', this._destroyRoom)

    this.room.destroy()
    this.room = null

    this._setMode(null)
    this.emit('peerState', 'inactive')
    this.emit('cloudState', 'not-started')
    this.emit('createProgress', null)
    this.emit('meta', null)
    this.emit('files', null)
    this.emit('url', null)

    if (err) {
      this.emit('error', err)
    }
  }

  destroy () {
    if (!this.destroyed) {
      this.destroyed = true
      this._destroyRoom()
    }
  }
}
