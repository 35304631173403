import { useCallback, useDebugValue, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import queueMicrotask from 'queue-microtask'
import Debug from 'debug'

import { useToast, useToastError } from './useToast.js'
import { useWarnBeforeLeave } from './useWarnBeforeLeave.js'

import { CREATE_MODE, JOIN_MODE, Send } from '../lib/Send.js'
import { shouldReportError } from '../lib/errors.js'
import { useTranslation } from 'react-i18next'

import { origin } from '../config.js'

const debug = Debug('wormhole:useSend')

export const useSend = (id, key, salt) => {
  const { t } = useTranslation()
  const router = useRouter()

  const [send, setSend] = useState(null)

  const [mode, setMode] = useState(id ? JOIN_MODE : null)
  const [peerState, setPeerState] = useState('inactive')
  const [cloudState, setCloudState] = useState('not-started')
  const [createProgress, setCreateProgress] = useState(null)
  const [downloading, setDownloading] = useState(false)

  const [roomMeta, setRoomMeta] = useState(null)
  const [files, setFiles] = useState(null)
  const [shareUrl, setShareUrl] = useState(null)

  const toast = useToast()
  const toastError = useToastError()

  const isExpired = roomMeta?.remainingDownloads === 0

  const handleUrl = useCallback(
    url => {
      setShareUrl(url)
      if (url) router.push(url)
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleError = useCallback(
    err => {
      toastError(err, {
        title: t('useSend.fileTransferError')
      })

      if (shouldReportError(err)) {
        // Ensure other error handlers run despite throwing
        queueMicrotask(() => {
          throw err
        })
      }
    },
    [toastError, t]
  )

  // Determine if we should warn about leaving the share URL before upload finishes
  let showWarnBeforeLeave = false
  let confirmLeaveMessage = null
  let unloadCanceledMessage = null
  if (mode === JOIN_MODE) {
    confirmLeaveMessage = t('useSend.confirmLeaveDownload')
    unloadCanceledMessage = t('useSend.unloadCanceledDownload')
    showWarnBeforeLeave = downloading && !isExpired
  } else if (mode === CREATE_MODE) {
    const cloudUploadFailed = cloudState === 'upload-failed'
    confirmLeaveMessage = cloudUploadFailed
      ? t('useSend.confirmLeaveP2P')
      : t('useSend.confirmLeaveCloud')
    unloadCanceledMessage = cloudUploadFailed
      ? t('useSend.unloadCanceledP2P')
      : t('useSend.unloadCanceledCloud')
    showWarnBeforeLeave = cloudState !== 'uploaded' && !isExpired
  }

  let warnIfLeavingPath = null
  if (showWarnBeforeLeave && shareUrl?.startsWith(origin)) {
    warnIfLeavingPath = shareUrl.slice(origin.length)
  }

  // Show warning before leaving page
  useWarnBeforeLeave(
    warnIfLeavingPath,
    confirmLeaveMessage,
    unloadCanceledMessage
  )

  useEffect(() => {
    const send = new Send()
    setSend(send)

    send.on('mode', setMode)
    send.on('peerState', setPeerState)
    send.on('cloudState', setCloudState)
    send.on('createProgress', setCreateProgress)
    send.on('downloading', setDownloading)

    send.on('meta', setRoomMeta)
    send.on('url', handleUrl)
    send.on('files', setFiles)
    send.on('error', handleError)

    return () => {
      send.destroy()

      send.off('mode', setMode)
      send.off('peerState', setPeerState)
      send.off('cloudState', setCloudState)
      send.off('createProgress', setCreateProgress)
      send.off('downloading', setDownloading)

      send.off('meta', setRoomMeta)
      send.off('url', handleUrl)
      send.off('files', setFiles)
      send.off('error', handleError)

      setSend(null)
    }
  }, [handleError, handleUrl])

  useEffect(() => {
    ;(async () => {
      try {
        await send?.join(id, key)
      } catch (err) {
        toastError(err, { title: t('useSend.joinError') })
        if (shouldReportError(err)) throw err
      }
    })()
  }, [send, id, key, toastError, t])

  // If room expired, show expiration page
  useEffect(() => {
    if (!isExpired) return

    const filesLength = files !== null ? files.length : 10
    toast({
      title: downloading
        ? t('useSend.roomExpiredWhileDownloading', { count: filesLength })
        : t('useSend.roomExpired', { count: filesLength }),
      description: downloading
        ? t('useSend.roomExpiredWhileDownloadingDescription')
        : t('useSend.roomExpiredDescription', { count: filesLength }),
      status: 'error'
    })

    // Force refresh page
    router.push({
      pathname: window.location.pathname,
      hash: window.location.hash,
      query: { error: 'gone' }
    })
  }, [downloading, files, isExpired, t, toast]) // eslint-disable-line react-hooks/exhaustive-deps

  // Show warning when uploader went offline
  useEffect(() => {
    ;(async () => {
      if (mode !== JOIN_MODE || !roomMeta) return

      const showMessage =
        ['upload-failed', 'sender-left'].includes(cloudState) &&
        roomMeta.numDownloadingPeers === 0

      if (showMessage) {
        toast({
          title: t('useSend.uploaderMissing'),
          description: t('useSend.uploaderMissingDescription'),
          status: 'warning'
        })
      }
    })()
  }, [cloudState, mode, roomMeta, toast, t])

  // Log state changes
  useEffect(() => {
    debug(
      `state mode=${mode}, peerState=${peerState}, cloudState=${cloudState}`
    )
  }, [mode, peerState, cloudState])

  useDebugValue(
    `mode=${mode}, peerState=${peerState}, cloudState=${cloudState}`
  )

  const create = useCallback(
    async uploadFiles => {
      try {
        await send.create(uploadFiles)
      } catch (err) {
        toastError(err, { title: t('useSend.createError') })
        // Don't filter using shouldReportError so that all errors stop the
        // warp effect in SendPage. shouldReportError is called there instead.
        throw err
      }
    },
    [send, toastError, t]
  )

  const getZipUrl = useCallback(() => send.room.getZipUrl(), [send])

  const handleDelete = useCallback(async () => {
    await send.room.handleDelete()

    // If the deletion succeeded, redirect to the homepage
    router.push('/')
  }, [send, router])

  const handleReport = useCallback(
    async reason => {
      await send.room.handleReport(reason)

      // If the report succeeded, redirect to the homepage
      router.push('/')
    },
    [send, router]
  )

  const handleRoomLifetimeChange = useCallback(
    lifetime => send.room.handleRoomLifetimeChange(lifetime),
    [send]
  )

  const handleMaxRoomDownloadsChange = useCallback(
    maxDownloads => send.room.handleMaxRoomDownloadsChange(maxDownloads),
    [send]
  )

  return {
    mode,
    roomMeta,
    peerState,
    cloudState,
    createProgress,
    files,
    shareUrl,

    create: send && create,
    getZipUrl: send && getZipUrl,
    handleDelete: send && handleDelete,
    handleReport: send && handleReport,
    handleRoomLifetimeChange: send && handleRoomLifetimeChange,
    handleMaxRoomDownloadsChange: send && handleMaxRoomDownloadsChange
  }
}
