import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast, useToastError } from '../../hooks/useToast.js'

export const DeleteButton = ({ filesLength, onDelete = () => {}, ...rest }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const toastError = useToastError()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    setIsLoading(true)

    try {
      await onDelete()
    } catch (err) {
      toastError(err, {
        title: t('deleteButton.errorTitle', { count: filesLength })
      })
      return
    } finally {
      setIsLoading(false)
    }

    toast({
      title: t('deleteButton.successTitle', { count: filesLength })
    })

    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} {...rest}>
        {t('deleteButton.delete')}
      </Button>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <form onSubmit={handleSubmit}>
            <AlertDialogHeader>
              {t('deleteButton.alertDialogHeading', { count: filesLength })}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {t('deleteButton.alertDialogBody', { count: filesLength })}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} colorScheme='gray' onClick={onClose}>
                {t('deleteButton.cancel')}
              </Button>
              <Button
                type='submit'
                colorScheme='red'
                ms={3}
                isLoading={isLoading}
              >
                {t('deleteButton.delete')}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
