import { useState } from 'react'
import {
  Box,
  Center,
  Flex,
  Grid,
  Img,
  ScaleFade,
  Stack,
  Text
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

import { maxRoomSizeGb, maxRoomCloudSizeGb } from '../config.js'
import { FancyHeading } from './FancyHeading.js'
import { FilePicker } from './FilePicker.js'
import { Panel } from './Panel.js'
import { RoomList } from './RoomList.js'
import { TooltipBox } from './TooltipBox.js'
import { TooltipText } from './TooltipText.js'
import { Link } from './Link.js'

export const SendPanel = ({ handleFiles }) => {
  const { t } = useTranslation()
  const [showRoomList, setShowRoomList] = useState(false)

  const handleChangeRooms = rooms => {
    setShowRoomList(rooms.length > 0)
  }

  return (
    <Panel>
      <Stack
        maxHeight={[null, null, '24rem']}
        direction={[showRoomList ? 'column' : 'column-reverse', null, 'row']}
        spacing={[10, null, 12]}
      >
        <Stack w={['full', null, '50%']}>
          <FilePicker
            onFiles={handleFiles}
            description={
              <TooltipBox
                label={
                  <Stack spacing={4}>
                    <Text>
                      {t('create.sizeCloudExplanation', {
                        maxRoomCloudSizeGb
                      })}
                    </Text>
                    <Text>
                      {t('create.sizeDirectExplanation', {
                        maxRoomCloudSizeGb
                      })}
                    </Text>
                  </Stack>
                }
                offset={[0, -4]}
              >
                <Box p={4} color='whiteAlpha.500' width='fit-content'>
                  {t('sendPanel.sendUpToMaxSize', { maxRoomSizeGb })}
                </Box>
              </TooltipBox>
            }
          />
        </Stack>
        <Box w={['full', null, '50%']}>
          <Grid
            templateColumns='repeat(auto-fill, minmax(100%, 1fr))'
            h='100%'
            overflow='auto'
          >
            <Box gridColumn={1} gridRow={1}>
              <ScaleFade in={showRoomList}>
                <RoomList onChange={handleChangeRooms} />
              </ScaleFade>
            </Box>
            <Box gridColumn={1} gridRow={1}>
              <ScaleFade in={!showRoomList} unmountOnExit>
                <Header />
              </ScaleFade>
            </Box>
          </Grid>
        </Box>
      </Stack>
    </Panel>
  )
}

const Header = () => {
  const { t } = useTranslation()
  return (
    <Flex direction='column' justify='space-between'>
      <Stack spacing={[6, null, 3]}>
        <FancyHeading
          fontSize={['3xl', '4xl']}
          textAlign={['center', null, 'initial']}
        >
          {t('send.heading')}
        </FancyHeading>

        <Text fontSize='lg'>
          <Trans
            t={t}
            i18nKey='send.siteDescription'
            components={{
              tooltip: <TooltipText label={t('create.encryptedTooltip')} />
            }}
          />
        </Text>
      </Stack>

      <Center mt='4'>
        <Link
          href='https://socket.dev'
          showExternalIcon={false}
          opacity='0.3'
          _hover={{
            textDecoration: 'none',
            transform: 'scale(1.05)',
            opacity: '1'
          }}
        >
          <Stack spacing='2' direction='row' align='center'>
            <Box me='1' fontSize='lg'>
              {t('send.protectedBySocket')}
            </Box>
            <Img src='/images/socket-logo-white.svg' w='110px' />
          </Stack>
        </Link>
      </Center>
    </Flex>
  )
}
