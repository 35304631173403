import { Box, Button, Icon, Collapse, Center, Flex } from '@chakra-ui/react'
import { useCallback, memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AiOutlineSend } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

import { enableTestShortcuts } from '../../config.js'
import { shouldReportError } from '../../lib/errors.js'
import { logEvent } from '../../lib/analytics.js'
import { CREATE_MODE } from '../../lib/Send.js'
import { useSend } from '../../hooks/useSend.js'
import { useShortcuts } from '../../hooks/useShortcuts.js'
import { useToastError } from '../../hooks/useToast.js'

import { ButtonLink } from '../ButtonLink.js'
import { CreatePanel } from '../CreatePanel.js'
import { FilePanel } from '../FilePanel.js'
import { Footer } from '../Footer.js'
import { Header } from '../Header.js'
import { SafeContainer } from '../SafeContainer.js'
import { SendPanel } from '../SendPanel.js'
import { Wormhole } from '../Wormhole.js'
import { captureException } from '../../lib/sentry.js'

export function SendPage () {
  const { t } = useTranslation()
  const router = useRouter()
  const { roomId = null } = router.query

  const key = globalThis.location?.hash.slice(1) || null
  const {
    mode,
    peerState,
    cloudState,
    createProgress,

    roomMeta,
    shareUrl,
    files,

    create,
    getZipUrl,
    handleDelete,
    handleReport,
    handleRoomLifetimeChange,
    handleMaxRoomDownloadsChange
  } = useSend(roomId, key)

  const toastError = useToastError()

  const [warp, setWarp] = useState(false)
  const [showFiles, setShowFiles] = useState(false)

  // TODO: Is there some way to just return a method from <Wormhole> and
  // remove this jank useEffect?
  useEffect(() => {
    if (!warp) {
      return
    }
    const timeout = setTimeout(() => {
      setWarp(false)
    }, 12000 + 2000) // Animation lasts for 12s, plus some buffer

    return () => {
      setWarp(false)
      clearTimeout(timeout)
    }
  }, [warp])

  const handleFiles = useCallback(
    async files => {
      if (create == null) return
      setShowFiles(false)
      setWarp(true)
      try {
        await create(files)
      } catch (err) {
        setWarp(false)
        if (shouldReportError(err)) throw err
      }
    },
    [create]
  )

  // Support the Share Target API to receive shared files from other apps
  useEffect(() => {
    if (!('serviceWorker' in navigator)) return

    // Ensure that create is non-null so we don't have to add/remove the event
    // listener twice on the first page load
    if (create == null) return

    const handleMessage = event => {
      const { type, files } = event.data
      if (type === 'share-target-files') {
        logEvent('shareTarget')
        handleFiles(files)
      }
    }
    navigator.serviceWorker.addEventListener('message', handleMessage)

    // Tell the service worker that page is ready to receive files
    navigator.serviceWorker.controller?.postMessage({
      type: 'share-target-ready'
    })

    return () => {
      try {
        navigator.serviceWorker.removeEventListener('message', handleMessage)
      } catch (err) {
        // This happens in UCBrowser
        captureException(err, { level: 'info' })
      }
    }
  }, [create, handleFiles])

  let shortcuts = {
    'Meta+s': () => {
      handleDownloadAll()
    }
  }
  if (enableTestShortcuts) {
    shortcuts = {
      ...shortcuts,
      1: () => {
        const files = [new globalThis.File(['abc'], 'abc.txt')]
        handleFiles(files)
      },
      2: () => {
        const files = [
          new globalThis.File([new Uint8Array(100_000_000)], 'abc.dat'),
          new globalThis.File([new Uint8Array(100_000_000)], 'def.dat'),
          new globalThis.File([new Uint8Array(100_000_000)], 'ghi.dat')
        ]
        handleFiles(files)
      },
      3: () => {
        const files = [
          new globalThis.File([new Uint8Array(500_000_000)], '1.dat'),
          new globalThis.File([new Uint8Array(500_000_000)], '2.dat'),
          new globalThis.File([new Uint8Array(500_000_000)], '3.dat')
        ]
        handleFiles(files)
      }
    }
  }
  useShortcuts(shortcuts)

  const handleDownload = async path => {
    const file = files.find(file => file.path === path)

    let url
    try {
      url = await file.getDownloadUrl()
    } catch (err) {
      captureException(err)
      toastError(err, {
        title: t('sendPage.downloadError')
      })
      return
    }
    if (url == null) return

    downloadFromUrl(url, file.name)
  }

  const handleDownloadAll = async () => {
    // Special case for 1 file download
    if (files.length === 1) {
      handleDownload(files[0].path)
      return
    }

    const name = `Wormhole ${roomId}.zip`

    let url
    try {
      url = await getZipUrl()
    } catch (err) {
      captureException(err)
      toastError(err, {
        title: t('sendPage.downloadError')
      })
      return
    }
    if (url == null) return

    downloadFromUrl(url, name)
  }

  const downloadFromUrl = (url, downloadAttribute) => {
    if (url.startsWith('blob:')) {
      const a = document.createElement('a')
      if (downloadAttribute != null) {
        a.download = downloadAttribute
      }
      a.href = url
      a.click()

      // It *should* be ok to revoke the url immediately; see
      // https://bugs.chromium.org/p/chromium/issues/detail?id=827932
      // Wait 30 seconds just to be sure.
      setTimeout(() => {
        URL.revokeObjectURL(url)
      }, 30 * 1000)
    } else {
      const iframe = document.createElement('iframe')
      iframe.hidden = true
      iframe.src = url
      document.body.appendChild(iframe)
      // TODO: should we remove the iframe from the dom?
      // It shouldn't be using up much memory since it doesn't render anything
    }
  }

  return (
    <>
      <Wormhole warp={warp} position='fixed' zIndex={-10} />
      <Flex direction='column' justify='space-between' minH='100vh'>
        <SendPageHeader
          mode={mode}
          maxW='6xl'
          bgGradient='linear(to-b, blackAlpha.600, transparent)'
        />

        <Box>
          <Collapse in={mode === CREATE_MODE && shareUrl} unmountOnExit>
            <SafeContainer maxW='3xl' py={8}>
              <CreatePanel
                cloudState={cloudState}
                peerState={peerState}
                createProgress={createProgress}
                roomMeta={roomMeta}
                shareUrl={shareUrl}
                filesLength={files?.length ?? 0}
                handleRoomLifetimeChange={handleRoomLifetimeChange}
                handleMaxRoomDownloadsChange={handleMaxRoomDownloadsChange}
              />
            </SafeContainer>
            <Collapse in={!showFiles} unmountOnExit>
              <Center>
                <Button onClick={setShowFiles} colorScheme='gray'>
                  {t('sendPage.previewFiles')}
                </Button>
              </Center>
            </Collapse>
          </Collapse>

          <Collapse in={mode !== CREATE_MODE || showFiles} unmountOnExit>
            <SafeContainer maxW='6xl' py={8}>
              {mode == null && <SendPanel handleFiles={handleFiles} />}
              {mode != null && (
                <FilePanel
                  mode={mode}
                  roomMeta={roomMeta}
                  files={files}
                  shareUrl={shareUrl}
                  isDisabled={peerState === 'inactive'}
                  onDownload={handleDownload}
                  onDownloadAll={handleDownloadAll}
                  handleDelete={handleDelete}
                  handleReport={handleReport}
                />
              )}
            </SafeContainer>
          </Collapse>
        </Box>
        <Footer
          maxW='6xl'
          bgGradient='linear(to-t, blackAlpha.600, transparent)'
        />
      </Flex>
    </>
  )
}

const SendPageHeader = memo(({ mode, ...rest }) => {
  const { t } = useTranslation()

  let buttons = null
  if (mode === CREATE_MODE) {
    buttons = [
      <ButtonLink
        key='send-button'
        size='sm'
        href='/'
        leftIcon={<Icon as={AiOutlineSend} boxSize={5} />}
      >
        {t('sendPage.sendMoreFiles')}
      </ButtonLink>
    ]
  } else if (mode === null) {
    buttons = []
  }
  return <Header buttons={buttons} {...rest} />
})
