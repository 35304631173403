/* eslint-env browser */

import { Box, usePrefersReducedMotion } from '@chakra-ui/react'
import { useCallback, useEffect, useRef } from 'react'
import Debug from 'debug'
import Head from 'next/head'
import throttle from 'throttleit'

import { exponentialRetry } from '../lib/fetcher.js'

const debug = Debug('wormhole:Wormhole')

const TICK_DURATION = 1000 / 60

// Render every n frames
const RENDER_EVERY_N_FRAMES = 3

// Render at a fraction of real display resolution in each dimension
const SCALE_FACTOR = 3 / 4

// Do not allow scale factor to be larger than this
const MAX_SCALE_FACTOR = 1

export const Wormhole = ({ warp, ...rest }) => {
  const tunnel = useRef(null)
  const prefersReducedMotion = usePrefersReducedMotion()

  const canvasRef = useCallback(node => {
    if (tunnel.current) {
      tunnel.current.destroy()
      tunnel.current = null
    }
    if (node !== null) {
      tunnel.current = new Tunnel(node)
    }
  }, [])

  useEffect(() => {
    if (!tunnel.current) return

    if (warp) {
      tunnel.current.warp()
    }

    return () => {
      tunnel.current?.stopWarp()
    }
  }, [warp])

  useEffect(() => {
    if (!tunnel.current) return
    tunnel.current.setAnimation(!prefersReducedMotion)
  }, [prefersReducedMotion])

  return (
    <Box {...rest}>
      <Head>
        <link
          rel='preload'
          href='/images/galaxy.jpg'
          as='image'
          crossOrigin='anonymous'
        />
      </Head>
      <canvas ref={canvasRef} />
    </Box>
  )
}

class Tunnel {
  isAnimationActive = true
  // force render on this tick, regardless of `isAnimationActive` setting?
  forceRender = true
  destroyed = false

  pixelRatioQuery = null

  // Lazy-loaded three.js library
  THREE = null

  mouse = null
  renderer = null
  camera = null
  scene = null

  curve = null
  tubeGeometry = null
  originalTubeGeometry = null
  tubeMaterial = null
  splineGeometry = null
  rotationSpeed = 0

  framesSinceLastRender = 0
  lastTime = null
  isWarping = false
  warpTimeout = null

  warpParams = {
    offsetX: 0,
    offsetY: 0,
    repeatX: 10,
    repeatY: 4,
    cameraShake: 0
  }

  hyperspace = null
  shake = null

  constructor (canvas) {
    this.canvas = canvas
    this.init()
  }

  async init () {
    const imagePromise = exponentialRetry(
      () =>
        new Promise((resolve, reject) => {
          const image = document.createElement('img')
          image.addEventListener('load', () => resolve(image), { once: true })
          image.addEventListener(
            'error',
            () => reject(new Error('Failed to load galaxy image')),
            { once: true }
          )
          image.crossOrigin = true
          image.src = '/images/galaxy.jpg'
        })
    )

    // Load the image and three.js in parallel
    const [THREE, image] = await Promise.all([
      import('../lib/three.js'),
      imagePromise
    ])

    if (this.destroyed) return

    this.THREE = THREE

    this.width = window.innerWidth
    this.height = window.innerHeight

    this.updateRotationSpeed()

    const texture = new this.THREE.Texture(image)
    texture.needsUpdate = true

    const mouseX = Math.random()
    const mouseY = Math.random()
    this.mouse = {
      position: new this.THREE.Vector2(
        this.width * mouseX,
        this.height * mouseY
      ),
      positionPercent: new this.THREE.Vector2(mouseX, mouseY),
      target: new this.THREE.Vector2(this.width * mouseX, this.height * mouseY)
    }

    try {
      this.renderer = new this.THREE.WebGLRenderer({
        antialias: true,
        canvas: this.canvas,
        failIfMajorPerformanceCaveat: true
      })
    } catch (err) {
      console.error(`Error creating WebGL renderer: ${err.message}`)
      this.destroy()
      return
    }

    // Used to trigger resize if devicePixelRatio changes
    this.pixelRatioQuery = window.matchMedia(
      `(resolution: ${window.devicePixelRatio}dppx)`
    )

    // Scale by SCALE_FACTOR, but never to > MAX_SCALE_FACTOR
    const pixelRatio = Math.min(
      window.devicePixelRatio * SCALE_FACTOR,
      MAX_SCALE_FACTOR
    )

    this.renderer.setPixelRatio(pixelRatio)
    this.renderer.setSize(this.width, this.height)

    this.camera = new this.THREE.PerspectiveCamera(
      15,
      this.width / this.height,
      0.01,
      1000
    )
    this.camera.rotation.y = Math.PI
    this.camera.position.z = 0.35
    this.updateCameraPosition()

    this.scene = new this.THREE.Scene()

    this.addListeners()

    this.createMesh(texture)

    // Start the animation loop
    this.render()
  }

  createMesh (texture) {
    // Spline geometry, with blank values. Updated in updateCurve(). Essentially,
    // it's a line that goes through the center of the tube.
    this.splineGeometry = new this.THREE.BufferGeometry()

    const points = []

    // High-level points that make up the tube
    for (let i = 0; i < 5; i += 1) {
      points.push(new this.THREE.Vector3(0, 0, 3 * (i / 4)))
    }

    // Bend tube so background doesn't show through
    points[4].y = -0.06

    // Make a spline curve from the high-level points
    this.curve = new this.THREE.CatmullRomCurve3(points)

    // The tube shape itself
    this.tubeGeometry = new this.THREE.TubeGeometry(
      this.curve,
      70,
      0.02,
      30,
      false
    )

    // The original tube geometry, i.e. the shape of the tube when it is not
    // warped and the mouse is at (0.5, 0.5). This copy is never modified.
    this.originalTubeGeometry = this.tubeGeometry.clone()

    // Warp the curve to it's final position
    this.updateCurve(Infinity)

    // The tube texture
    this.tubeMaterial = new this.THREE.MeshBasicMaterial({
      side: this.THREE.BackSide,
      map: texture
    })
    this.tubeMaterial.map.wrapS = this.THREE.MirroredRepeatWrapping
    this.tubeMaterial.map.wrapT = this.THREE.MirroredRepeatWrapping
    this.updateMaterialOffset()

    // The tube mesh, combination of geometry and mesh
    const tubeMesh = new this.THREE.Mesh(this.tubeGeometry, this.tubeMaterial)

    // Show it
    this.scene.add(tubeMesh)
  }

  updateRotationSpeed () {
    if (this.width > 2500) {
      this.rotationSpeed = 0.00005
    } else if (this.width > 2000) {
      this.rotationSpeed = 0.000075
    } else if (this.width > 1500) {
      this.rotationSpeed = 0.0001
    } else if (this.width > 1000) {
      this.rotationSpeed = 0.0002
    } else if (this.width > 500) {
      this.rotationSpeed = 0.0003
    } else {
      this.rotationSpeed = 0.0004
    }
  }

  resize () {
    const oldWidth = this.width
    const oldHeight = this.height

    this.width = window.innerWidth
    this.height = window.innerHeight

    this.updateRotationSpeed()

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()

    this.pixelRatioQuery.removeListener(this.handleResize)
    this.pixelRatioQuery = window.matchMedia(
      `(resolution: ${window.devicePixelRatio}dppx)`
    )
    this.pixelRatioQuery.addListener(this.handleResize)

    // Scale by SCALE_FACTOR, but never to > MAX_SCALE_FACTOR
    const pixelRatio = Math.min(
      window.devicePixelRatio * SCALE_FACTOR,
      MAX_SCALE_FACTOR
    )

    this.renderer.setPixelRatio(pixelRatio)
    this.renderer.setSize(this.width, this.height)

    if (oldWidth != null && oldHeight != null) {
      this.mouse.position.x *= this.width / oldWidth
      this.mouse.position.y *= this.height / oldHeight
      this.mouse.target.x *= this.width / oldWidth
      this.mouse.target.y *= this.height / oldHeight
    }
  }

  setAnimation (isActive) {
    this.isAnimationActive = isActive
  }

  render = time => {
    if (this.destroyed) return

    // Always render at least once
    let renderThisFrame = this.forceRender
    if (this.isAnimationActive) {
      this.framesSinceLastRender += 1
      if (this.framesSinceLastRender >= RENDER_EVERY_N_FRAMES) {
        renderThisFrame = true
      } else if (this.isWarping) {
        // Render every frame during warp for smoother animation
        renderThisFrame = true
      }
    }

    if (renderThisFrame) {
      if (time != null && this.lastTime != null) {
        const delta = time - this.lastTime

        if (this.isWarping) {
          this.updateMousePosition(delta)
        }
        this.updateRotation(delta)
        this.updateMaterialOffset()
        if (this.isWarping) {
          this.updateCameraPosition()
          this.updateCurve(delta)
        }

        this.forceRender = false
      }

      this.renderer.render(this.scene, this.camera)

      this.lastTime = time
      this.framesSinceLastRender = 0
    }

    // Re-render at the native refresh rate
    window.requestAnimationFrame(this.render)
  }

  async warp () {
    if (this.destroyed || !this.isAnimationActive || this.isWarping) return
    debug('warp')

    this.isWarping = true

    const { gsap, RoughEase } = await import('../lib/gsap.js')

    if (!this.isWarping) return

    // Ensure that in worst case warp animation is always stopped 2s after it
    // finishes animating
    this.warpTimeout = setTimeout(() => {
      this.stopWarp()
    }, 12000 + 2000)

    this.hyperspace = gsap.timeline()

    this.hyperspace.to(this.warpParams, 4, {
      repeatX: 0.3,
      ease: 'power1.easeInOut'
    })
    this.hyperspace.to(
      this.warpParams,
      12,
      {
        offsetX: 8,
        ease: 'power2.easeInOut'
      },
      0
    )
    this.hyperspace.to(
      this.warpParams,
      6,
      {
        repeatX: 10,
        ease: 'power2.easeInOut'
      },
      '-=5'
    )

    this.shake = gsap.timeline()
    this.shake.to(
      this.warpParams,
      2,
      {
        cameraShake: -0.005,
        ease: RoughEase.ease.config({
          template: 'power0.easeNone',
          strength: 0.5,
          points: 100,
          taper: 'none',
          randomize: true,
          clamp: false
        })
      },
      4
    )
    this.shake.to(this.warpParams, 1, {
      cameraShake: 0,
      ease: RoughEase.ease.config({
        template: 'power0.easeNone',
        strength: 0.5,
        points: 100,
        taper: 'none',
        randomize: true,
        clamp: false
      })
    })
  }

  stopWarp () {
    if (this.destroyed || !this.isWarping) return
    debug('stopWarp')

    clearTimeout(this.warpTimeout)
    this.isWarping = false

    if (this.hyperspace) {
      this.hyperspace.kill()
      this.warpParams.offsetX = 0
      this.warpParams.repeatX = 10
    }
    if (this.shake) {
      this.shake.kill()
      this.warpParams.cameraShake = 0
    }
  }

  updateMousePosition (delta) {
    this.mouse.position.x +=
      (this.mouse.target.x - this.mouse.position.x) *
      Math.min(1, delta / TICK_DURATION / 50)
    this.mouse.position.y +=
      (this.mouse.target.y - this.mouse.position.y) *
      Math.min(1, delta / TICK_DURATION / 50)

    this.mouse.positionPercent.x = this.mouse.position.x / this.width
    this.mouse.positionPercent.y = this.mouse.position.y / this.height
  }

  updateRotation (delta) {
    this.warpParams.offsetY += this.rotationSpeed * (delta / TICK_DURATION)
  }

  updateMaterialOffset () {
    this.tubeMaterial.map.offset.x = this.warpParams.offsetX
    this.tubeMaterial.map.offset.y = this.warpParams.offsetY
    this.tubeMaterial.map.repeat.x = this.warpParams.repeatX
    this.tubeMaterial.map.repeat.y = this.warpParams.repeatY
  }

  updateCameraPosition () {
    this.camera.position.x =
      this.mouse.positionPercent.x * 0.044 - 0.025 + this.warpParams.cameraShake
    this.camera.position.y = this.mouse.positionPercent.y * 0.044 - 0.025
  }

  updateCurve (delta) {
    // Warp the high-level points based on the mouse position
    this.curve.points[2].x = this.curve.points[4].x =
      0.6 * (1 - this.mouse.positionPercent.x) - 0.3
    this.curve.points[2].y = this.curve.points[4].y =
      0.6 * (1 - this.mouse.positionPercent.y) - 0.3

    // Update the spline mesh with the new points
    const points = this.curve.getPoints(70)
    this.splineGeometry.setFromPoints(points)

    // Create vertexes which are updated in the loop below
    const vertex = new this.THREE.Vector3()
    const originalVertex = new this.THREE.Vector3()
    const splineVertex = new this.THREE.Vector3()

    // Get the position attributes from the various geometries
    const tubePosition = this.tubeGeometry.getAttribute('position')
    const originalTubePosition = this.originalTubeGeometry.getAttribute(
      'position'
    )
    const splinePosition = this.splineGeometry.getAttribute('position')

    // The number of tube segments, plus one
    const segmentPoints = tubePosition.count / splinePosition.count

    // Warp the tube geometry based on the updated spline mesh
    for (let i = 0; i < tubePosition.count; i += 1) {
      vertex.fromBufferAttribute(tubePosition, i)
      originalVertex.fromBufferAttribute(originalTubePosition, i)

      const index = Math.floor(i / segmentPoints)
      splineVertex.fromBufferAttribute(splinePosition, index)

      tubePosition.setX(
        i,
        vertex.x +
          (originalVertex.x + splineVertex.x - vertex.x) *
            Math.min(1, delta / TICK_DURATION / 10)
      )

      tubePosition.setY(
        i,
        vertex.y +
          (originalVertex.y + splineVertex.y - vertex.y) *
            Math.min(1, delta / TICK_DURATION / 10)
      )
    }
    tubePosition.needsUpdate = true
  }

  handleResize = throttle(() => {
    this.resize()
    this.forceRender = true
  }, 250)

  handleMouseMove = event => {
    if (!this.isWarping) {
      return
    }
    this.mouse.target.x = event.clientX
    this.mouse.target.y = event.clientY
  }

  addListeners () {
    this.pixelRatioQuery.addListener(this.handleResize)
    window.addEventListener('resize', this.handleResize)
    document.body.addEventListener('mousemove', this.handleMouseMove)
  }

  removeListeners () {
    if (this.pixelRatioQuery != null) {
      this.pixelRatioQuery.removeListener(this.handleResize)
    }
    window.removeEventListener('resize', this.handleResize)
    document.body.removeEventListener('mousemove', this.handleMouseMove)
  }

  destroy () {
    if (this.destroyed) return
    this.destroyed = true

    clearTimeout(this.warpTimeout)

    this.removeListeners()
  }
}
