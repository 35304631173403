import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast, useToastError } from '../../hooks/useToast.js'

export const ReportButton = ({
  filesLength = 1,
  onReport = () => {},
  ...rest
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const toastError = useToastError()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [reason, setReason] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()

    setIsLoading(true)

    try {
      await onReport(reason)
    } catch (err) {
      toastError(err, {
        title: t('reportButton.errorTitle')
      })
      return
    } finally {
      setIsLoading(false)
    }

    toast({
      title: t('reportButton.successTitle'),
      description: t('reportButton.successDescription')
    })

    setReason(null)
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} {...rest}>
        {t('reportButton.report')}
      </Button>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <form onSubmit={handleSubmit}>
            <AlertDialogHeader>
              {t('reportButton.alertDialogHeading', { count: filesLength })}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <RadioGroup onChange={setReason} value={reason}>
                <Stack>
                  <Radio value='malware'>
                    {t('reportButton.malwareOrPhishing')}
                  </Radio>
                  <Radio value='other'>{t('reportButton.other')}</Radio>
                </Stack>
              </RadioGroup>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} colorScheme='gray' onClick={onClose}>
                {t('reportButton.cancel')}
              </Button>
              <Button
                type='submit'
                colorScheme='red'
                ms={3}
                isDisabled={reason == null}
                isLoading={isLoading}
              >
                {t('reportButton.report')}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
