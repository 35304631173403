import { openDB } from 'idb'

import { captureMessage } from './sentry.js'

const DB_NAME = 'rooms'
const STORE_NAME = 'rooms'

class RoomStorage {
  static storageInstance = null

  constructor () {
    this.dbPromise = (async () => {
      let db
      try {
        db = await openDB(DB_NAME, undefined, {
          upgrade: db => {
            db.createObjectStore(STORE_NAME, {
              keyPath: 'id'
            })
          },
          blocked: () => {
            captureMessage('RoomStorage DB open blocked')
          },
          blocking: () => {
            captureMessage('RoomStorage DB open blocking')
          },
          terminated: () => {
            captureMessage('RoomStorage DB unexpectedly closed')
          }
        })
      } catch (err) {
        console.log('IndexedDB not available')
        return null
      }

      return db
    })()
  }

  static async getDBInstance () {
    if (!this.storageInstance) this.storageInstance = new RoomStorage()
    return this.storageInstance.dbPromise
  }
}

export async function getRooms () {
  const db = await RoomStorage.getDBInstance()
  if (!db) return []

  return db.getAll(STORE_NAME)
}

export async function addOrReplaceRoom (room) {
  const db = await RoomStorage.getDBInstance()
  if (!db) return

  await db.put(STORE_NAME, room)
}

export async function removeRoomById (roomId) {
  const db = await RoomStorage.getDBInstance()
  if (!db) return

  await db.delete(STORE_NAME, roomId)
}
