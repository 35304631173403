import {
  Box,
  Button,
  Collapse,
  Fade,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Wrap
} from '@chakra-ui/react'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { useTranslation, Trans } from 'react-i18next'
import { memo } from 'react'

import { JOIN_MODE } from '../lib/Send.js'
import { useBreakpointValue } from '../hooks/useBreakpointValue.js'

import { FancyHeading } from './FancyHeading.js'
import { FileList } from './FileList.js'
import { Panel } from './Panel.js'
import { RelativeTime } from './RelativeTime.js'
import { Share } from './Share.js'
import { TooltipText } from './TooltipText.js'
import { DeleteButton } from './buttons/DeleteButton.js'
import { ReportButton } from './buttons/ReportButton.js'

export const FilePanel = ({
  mode,
  roomMeta,
  files,
  shareUrl,
  isDisabled,

  onDownload,
  onDownloadAll,
  handleDelete,
  handleReport
}) => {
  if (files == null) files = []
  const isMobileBreakpoint = useBreakpointValue([true, true, false])

  return (
    <Panel>
      <Stack
        direction={['column-reverse', null, 'row']}
        spacing={[8, null, 12]}
      >
        <Stack spacing={6} w={['full', null, '50%']}>
          <FilePanelPrimary
            files={files}
            isDisabled={isDisabled}
            onDownload={onDownload}
            onDownloadAll={onDownloadAll}
          />
          {isMobileBreakpoint && (
            <FilePanelTertiary
              mode={mode}
              shareUrl={shareUrl}
              roomMeta={roomMeta}
              filesLength={files.length}
              handleDelete={handleDelete}
              handleReport={handleReport}
            />
          )}
        </Stack>

        <Stack spacing={6} w={['full', null, '50%']}>
          <FilePanelSecondary filesLength={files.length} />
          {!isMobileBreakpoint && (
            <FilePanelTertiary
              mode={mode}
              shareUrl={shareUrl}
              roomMeta={roomMeta}
              filesLength={files.length}
              handleDelete={handleDelete}
              handleReport={handleReport}
            />
          )}
        </Stack>
      </Stack>
    </Panel>
  )
}

const FilePanelPrimary = ({ files, isDisabled, onDownload, onDownloadAll }) => {
  const { t } = useTranslation()

  // HACK: Disable download all button if there's a file greater than 4GB
  // See: https://github.com/SocketDev/wormhole/issues/1062
  const downloadAllSupported =
    files.length === 1 || files.every(file => file.length < 2 ** 32)

  const downloadAllButtonSize = useBreakpointValue(['sm', 'md'])

  return (
    <>
      <Flex justify='space-between' align='center'>
        <Heading fontSize={['xl', 'xl', '2xl']}>
          {files.length === 0 && <span>Loading…</span>}
          {files.length > 0 && (
            <span>
              {files.length === 0
                ? t('filePanel.files')
                : t('filePanel.filesCount', { count: files.length })}
            </span>
          )}
        </Heading>
        <Box>
          {downloadAllSupported && (
            <Button
              isDisabled={isDisabled || files.length === 0}
              size={downloadAllButtonSize}
              leftIcon={<Icon as={AiOutlineCloudDownload} boxSize={[5, 6]} />}
              onClick={onDownloadAll}
            >
              {t('filePanel.downloadFiles', { count: files.length })}
            </Button>
          )}
        </Box>
      </Flex>

      <FileList files={files} isDisabled={isDisabled} onDownload={onDownload} />
    </>
  )
}

const FilePanelSecondary = memo(({ filesLength }) => {
  const { t } = useTranslation()

  return (
    <Stack align={['center', null, 'initial']} spacing={[6, null, 3]}>
      <FancyHeading fontSize='4xl' textAlign={['center', null, 'initial']}>
        {t('filePanel.youveGotFiles', { count: filesLength })}
      </FancyHeading>

      <Text fontSize={['md', null, 'lg']}>
        <Trans
          t={t}
          i18nKey='filePanel.siteDescriptionJoin'
          components={{
            tooltip: <TooltipText label={t('tooltips.e2eJoin')} />
          }}
        />
      </Text>
    </Stack>
  )
})

const FilePanelTertiary = ({
  mode,
  shareUrl,
  roomMeta,
  filesLength,
  handleDelete,
  handleReport
}) => {
  const { t } = useTranslation()
  const numDownloadingPeers = roomMeta?.numDownloadingPeers ?? 0

  return (
    <>
      <Stack spacing={4}>
        <Heading as='h4' size='md'>
          {t('filePanel.share', { count: filesLength })}
        </Heading>
        <Share url={shareUrl} size='md' w='full' />
      </Stack>
      <Collapse in={numDownloadingPeers > 0}>
        <Text fontSize='lg'>
          {t('filePanel.numDownloadingPeers', {
            count: numDownloadingPeers
          })}
        </Text>
      </Collapse>

      <Fade
        in={
          roomMeta?.expiresAtTimestampMs != null &&
          roomMeta?.remainingDownloads != null
        }
      >
        <Wrap spacing={4}>
          <Text color='whiteAlpha.600'>
            <Trans
              t={t}
              i18nKey='filePanel.expiresAt'
              count={roomMeta?.remainingDownloads ?? 0}
              components={{
                relativetime: (
                  <RelativeTime to={roomMeta?.expiresAtTimestampMs} />
                )
              }}
              values={{
                // TODO: remove remainingDownloads once translations are updated.
                // Translations should be using count instead.
                remainingDownloads: roomMeta?.remainingDownloads ?? 0
              }}
            />
          </Text>

          <DeleteButton
            colorScheme='red'
            size='xs'
            filesLength={filesLength}
            onDelete={handleDelete}
          />

          {mode === JOIN_MODE && (
            <ReportButton
              colorScheme='gray'
              size='xs'
              color='whiteAlpha.600'
              filesLength={filesLength}
              onReport={handleReport}
            />
          )}
        </Wrap>
      </Fade>
    </>
  )
}
